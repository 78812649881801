import { useState } from "react";
import { Link } from "react-router-dom";
import { TitleCard, SearchInput, useTitle } from "../components";
import {
  ElementaryGrammarList,
  PreIntermediateGrammarList,
} from "../data/GrammarData";

export const Grammar = ({ title }) => {
  const [searchResults, setSearchResults] = useState();

  const GrammarCards = [
    {
      heading: "A1 - A2",
      subheading: "Elementary",
      url: "/grammar/elementary",
    },
    {
      heading: "A2 - B1",
      subheading: "Pre-Intermediate",
      url: "/grammar/preIntermediate",
    },
    {
      heading: "B1 - B2",
      subheading: "Intermediate",
      url: "/underConstruction",
    },
    {
      heading: "B2 - C1",
      subheading: "Upper-Intermediate",
      url: "/underConstruction",
    },
    { heading: "C1 - C2", subheading: "Advanced", url: "/underConstruction" },
  ];

  const GrammarList = [...ElementaryGrammarList, ...PreIntermediateGrammarList];

  useTitle(title);

  return (
    <div>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">Grammar</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div data-aos="zoom-in" data-aos-duration="800">
        <h1 className="text-xl min-[344px]:text-3xl sm:text-5xl drop-shadow text-pink-700 text-center font-bold w-3/4 lg:w-2/3 lg:leading-normal m-auto pt-8">
          Grammar
        </h1>
        <div className="text-xl sm:text-2xl drop-shadow text-indigo-950 text-center w-3/4 lg:w-2/3 lg:leading-normal m-auto pt-4">
          You can choose a level, or search for a grammatical point.
        </div>

        <SearchInput List={GrammarList} setSearchResults={setSearchResults} />

        <div
          className={`${
            !searchResults && "hidden"
          } w-4/5 md:w-11/12 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 my-10 md:my-16 mx-auto justify-around md:pl-12`}
        >
          {!searchResults ? (
            <span className="hidden">original</span>
          ) : searchResults.length === 0 ? (
            <span className="text-2xl text-fuchsia-950 drop-shadow">
              No results were found!
            </span>
          ) : (
            searchResults.map((point) => (
              <Link
                to={point.url}
                key={point.id}
                className="flex items-center text-xl font-medium dropshadow text-fuchsia-950 duration-300 hover:text-pink-700 hover:scale-110 md:hover:translate-x-2 pb-2 justify-center lg:justify-start"
              >
                <span className="flex flex-col text-center lg:text-start sm:flex-row xl:flex-col pt-1">
                  <span className="sm:pr-1">{point.topic}</span>
                  <span className="text-lg font-normal">({point.level})</span>
                </span>
              </Link>
            ))
          )}
        </div>

        <div
          className={`${
            searchResults && "hidden"
          } flex flex-wrap md:max-w-3xl mx-auto mt-14 mb-12 justify-center`}
        >
          {GrammarCards.map((card) => (
            <TitleCard key={card.heading} card={card} />
          ))}
        </div>
      </div>
    </div>
  );
};
