import { useNavigate } from "react-router-dom";
import { useTitle } from "../components";
import UnderConstructionImage from "../assets/images/underConstructionImage.svg";

export const PageUnderConstruction = ({title}) => {
  const navigate = useNavigate();

  useTitle(title);

  return (
    <div
      className="flex flex-col-reverse items-center my-12 md:flex-row md:justify-evenly md:my-16 lg:my-20"
      data-aos="zoom-in"
      data-aos-duration="800"
    >
      <div className="w-3/5 md:w-3/12">
        <img src={UnderConstructionImage} alt="Page Under Construction" />
      </div>
      <div className="text-center  mb-12 md:mb-0">
        <p className="text-indigo-950 font-semibold text-xl sm:text-2xl mb-3 lg:text-2xl xl:mb-5">
          Something awesome is <br /> in the works.
        </p>
        <h2 className="text-pink-700 font-bold text-3xl sm:text-4xl lg:text-5xl">
          Check back soon!
        </h2>
        <button
          onClick={() => navigate(-1)}
          className="bg-fuchsia-950 duration-100 hover:bg-fuchsia-900 text-white tracking-wider rounded-3xl pt-1 pb-2 px-4 mt-5 md:mt-6 md:text-lg lg:mt-8 lg:text-xl xl:mt-10 "
        >
          Back
        </button>
      </div>
    </div>
  );
};
