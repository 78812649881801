import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LinkImg from "../../../assets/images/link.svg";

export const BeGoingToElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      <span className="mb-3 block">
        Complete the sentences using the correct form of "be going to" and a
        verb from the list.
      </span>
      <span className="border p-1 w-fit block">
        pass | buy | start | visit | watch | break | win
      </span>
    </span>
  );
  const questions = [
    {
      id: 1,
      question: <span>Be careful! The glass ____.</span>,
      answer: (
        <span className="font-normal">
          Be careful! The glass{" "}
          <span className="font-bold">is going to break</span>.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>____ you ____ your grandma this weekend?</span>,
      answer: (
        <span>
          <span className="font-bold">Are</span> you{" "}
          <span className="font-bold">going to visit</span> your gradma this
          weekend?
        </span>
      ),
    },
    {
      id: 3,
      question: <span>She never studies. I think she ____ her exam.</span>,
      answer: (
        <span>
          She never studies. I think she{" "}
          <span className="font-bold">isn't going to pass</span> her exam.
        </span>
      ),
    },
    {
      id: 4,
      question: <span>She ____ a new dress tomorrow.</span>,
      answer: (
        <span>
          She<span className="font-bold">'s going to buy</span> a new dress
          tomorrow.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>They're running so fast. They ____ the race.</span>,
      answer: (
        <span>
          They're running so fast. They
          <span className="font-bold">'re going to win</span> the race.
        </span>
      ),
    },
    {
      id: 6,
      question: <span>____ he ____ a new job?</span>,
      answer: (
        <span>
          <span className="font-bold">Is</span> he{" "}
          <span className="font-bold">going to start</span> a new job?
        </span>
      ),
    },
    {
      id: 7,
      question: <span>We ____ a movie tonight.</span>,
      answer: (
        <span>
          We<span className="font-bold">'re going to watch</span> a movie
          tonight.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Be Going To</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Be Going to
          </h1>
          <div className="mb-8">
            <p className="mb-4">We use the following structure:</p>
            <p className="mb-4 font-bold text-center">
              be (am/is/are) going to + base form of a verb
            </p>
            <div className="flex mb-4">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                to to talk about future plans or intentions
              </span>
            </div>

            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
              <p className="font-semibold pb-5 italic">Examples:</p>{" "}
              <p className="pb-2 italic">
                Jenny is saving her money. She
                <span className="font-bold">'s going to buy</span> a new car.
              </p>
              <p className="pb-2 italic">
                He’s training at the gym every day because he
                <span className="font-bold">’s going to participate</span> in a
                marathon.
              </p>
            </div>
            <div className="flex mb-4">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                to make predictions about the future when we can see some
                evidence now
              </span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
              <p className="font-semibold pb-5 italic">Examples:</p>{" "}
              <p className="pb-2 italic">
                Look at those dark clouds. It
                <span className="font-bold">'s going to rain</span> tonight.
              </p>
              <p className="pb-2 italic">
                He feels sick. I think he{" "}
                <span className="font-bold">is going to stay</span> home today.
              </p>
            </div>
            <p className="flex">
              <img
                className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
                src={Bulb}
                alt="Bulb"
              />
              <span className="pl-3">
                We usually use this structure with future time expressions like
                "tomorrow", "next week", "next year", "in two days", or "this
                evening".
              </span>
            </p>
          </div>

          <p className="mb-9">
            Below is an overview of the different forms of this structure for
            the verb "do".
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Positive</h3>
            <p>
              I <span className="font-bold">am</span> / I
              <span className="font-bold">'m</span> going to do ...
            </p>
            <p>
              You <span className="font-bold">are</span> / You
              <span className="font-bold">'re</span> going to do ...
            </p>
            <p>
              He <span className="font-bold">is</span> / He
              <span className="font-bold">'s</span> going to do ...
            </p>
            <p>
              She <span className="font-bold">is</span> / She
              <span className="font-bold">'s</span> going to do ...
            </p>
            <p>
              It <span className="font-bold">is</span> / It
              <span className="font-bold">'s</span> going to do ...
            </p>
            <p>
              We <span className="font-bold">are</span> / We
              <span className="font-bold">'re</span> going to do ...
            </p>
            <p>
              You <span className="font-bold">are</span> / You
              <span className="font-bold">'re</span> going to do ...
            </p>
            <p>
              They <span className="font-bold">are</span> / They
              <span className="font-bold">'re</span> going to do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Negative</h3>
            <p>
              I <span className="font-semibold">am</span>{" "}
              <span className="font-semibold text-red-700">not</span> going to
              do ...
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              He <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              She <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              It <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              We <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              They <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> / They
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you going to do... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they going to do ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I{" "}
              <span className="font-semibold text-red-700">not</span> going to
              do ...?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> he going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> she going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> it going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> we going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> they going
              to do ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">am</span>. / No, I
              <span className="font-semibold">'m</span>
              <span className="font-semibold text-red-700"> not</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">is</span>. / No, he{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">is</span>. / No, she{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">is</span>. / No, it{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">are</span>. / No, we{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">are</span>. / No, they{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, we use the auxiliary verbs "am", "is" or "are" to
              make negative sentences and questions using this structure.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-20">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">It is / It's going to rain.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">It is not / isn't going to rain.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Is it going to rain?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Isn't it going to rain? / Is it not going to rain?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, it is. / No, it isn't.</span>
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/futureSimple">
                The Simple Future{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Be Going to
          </h1>
          <div className="mb-8">
            <p lang="fa" dir="rtl" className="mb-4">
              در دو زمان از ساختار زیر استفاده می کنیم:
            </p>
            <p className="mb-4 font-bold text-center">
              be (am/is/are) going to + base form of a verb
            </p>
            <div dir="rtl" className="flex mb-4">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span lang="fa" className="pt-2">
                وقتی می خواهیم راجع به برنامه ها و مقاصد آینده صحبت کنیم
              </span>
            </div>

            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
              <p lang="fa" dir="rtl" className="font-semibold pb-5">
                مثال:
              </p>{" "}
              <p className="pb-1 italic">
                Jenny is saving her money. She
                <span className="font-bold">'s going to buy</span> a new car.
              </p>
              <p lang="fa" dir="rtl" className="mb-3">
                جنی دارد پولش را جمع می کند. قصد دارد یک ماشین بخرد.
              </p>
              <p className="pb-1 italic">
                He’s training at the gym every day because he
                <span className="font-bold">’s going to participate</span> in a
                marathon.
              </p>
              <p lang="fa" dir="rtl" className="mb-2">
                هر روز در باشگاه تمرین می کند چون می خواهد در یک مسابقه ماراتون
                شرکت کند.
              </p>
            </div>
            <div dir="rtl" className="flex mb-4">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span lang="fa" className="pt-2">
                وقتی بر اساس شواهد موجود درباره آینده پیش بینی می کنیم
              </span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
              <p lang="fa" dir="rtl" className="font-semibold pb-5">
                مثال:
              </p>{" "}
              <p className="pb-1 italic">
                Look at those dark clouds. It
                <span className="font-bold">'s going to rain</span> tonight.
              </p>
              <p lang="fa" dir="rtl" className="mb-3">
                آن ابرهای تیره را ببین. می خواهد باران بگیرد.
              </p>
              <p className="pb-2 italic">
                He feels sick. I think he{" "}
                <span className="font-bold">is going to stay</span> home today.
              </p>
              <p lang="fa" dir="rtl" className="mb-1">
                حالش خیلی خوب نیست. فکر می کنم امروز خانه بماند.
              </p>
            </div>
            <p dir="rtl" className="flex">
              <img
                className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
                src={Bulb}
                alt="Bulb"
              />
              <span lang="fa" className="pr-3">
                این ساختار را معمولا با قید های زمان آینده مانند in two days
                ،next year ،next week ،tomorrow یا this evening استفاده می کنیم.
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            در ادامه این ساختار را با فعل do به عنوان مثال در شکل های مختلف جمله
            مشاهده می کنید.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Positive</h3>
            <p>
              I <span className="font-bold">am</span> / I
              <span className="font-bold">'m</span> going to do ...
            </p>
            <p>
              You <span className="font-bold">are</span> / You
              <span className="font-bold">'re</span> going to do ...
            </p>
            <p>
              He <span className="font-bold">is</span> / He
              <span className="font-bold">'s</span> going to do ...
            </p>
            <p>
              She <span className="font-bold">is</span> / She
              <span className="font-bold">'s</span> going to do ...
            </p>
            <p>
              It <span className="font-bold">is</span> / It
              <span className="font-bold">'s</span> going to do ...
            </p>
            <p>
              We <span className="font-bold">are</span> / We
              <span className="font-bold">'re</span> going to do ...
            </p>
            <p>
              You <span className="font-bold">are</span> / You
              <span className="font-bold">'re</span> going to do ...
            </p>
            <p>
              They <span className="font-bold">are</span> / They
              <span className="font-bold">'re</span> going to do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Negative</h3>
            <p>
              I <span className="font-semibold">am</span>{" "}
              <span className="font-semibold text-red-700">not</span> going to
              do ...
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              He <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              She <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              It <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              We <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> /
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
            <p>
              They <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> / They
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> going to
              do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we going to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you going to do... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they going to do ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I{" "}
              <span className="font-semibold text-red-700">not</span> going to
              do ...?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> he going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> she going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> it going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> we going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you going
              to do ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they{" "}
              <span className="font-semibold text-red-700">not</span> /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> they going
              to do ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">am</span>. / No, I
              <span className="font-semibold">'m</span>
              <span className="font-semibold text-red-700"> not</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">is</span>. / No, he{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">is</span>. / No, she{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">is</span>. / No, it{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">are</span>. / No, we{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">are</span>. / No, they{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              همانطور که مشاهده می کنید وقتی از این ساختار استفاده می کنیم برای
              منفی و سوالی کردن جملات از افعال کمکی is ،am یا are استفاده می
              کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-20">
            <p lang="fa" dir="rtl" className="font-semibold pb-6 ">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">It is / It's going to rain.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              باران خواهد بارید.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">It is not / isn't going to rain.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              باران نخواهد بارید.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Is it going to rain?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              آیا باران خواهد بارید؟
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Isn't it going to rain? / Is it not going to rain?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              آیا باران نخواهد بارید؟
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, it is. / No, it isn't.</span>
            </p>
          </div>
          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/futureSimple">
                The Simple Future{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
