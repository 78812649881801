import { Link } from "react-router-dom";
import { useTitle } from "../components";

export const Contact = ({ title }) => {
  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>

          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Contact Us</span>
        </nav>
      </div>

      {/* -------------------- Body in English -------------------- */}
      <div
        data-aos="zoom-in"
        data-aos-duration="800"
        className="mt-7 mb-12 mx-9 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-6/12"
      >
        <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
          Contact Us
        </h1>
        <form
          className="flex flex-col border bg-pink-50 shadow-md rounded-xl"
          action="/messageSubmitted"
          name="contactUs"
          autoComplete="on"
          method="post"
          netlify-honeypot="bot-field"
          netlify
        >
          <input type="hidden" name="form-name" value="contactUs" />
          <input type="text" name="bot-field" hidden />
          <input
            className="mx-3 mt-3 md:mx-6 md:mt-6 rounded-xl outline-none pl-3 py-2 bg-white text-base  text-indigo-950 "
            type="text"
            name="name"
            placeholder="Name"
            maxLength={30}
            pattern="[A-Za-z\s]+"
            autoComplete="off"
            required
          />
          <input
            className="mx-3 mt-3 md:mx-6 md:mt-6 rounded-xl outline-none pl-3 py-2 bg-white text-base  text-indigo-950 "
            type="email"
            name="email"
            placeholder="Email"
            maxLength={50}
            autoComplete="off"
            required
          />
          <textarea
            className="mx-3 mt-3 md:mx-6 md:mt-6 rounded-xl outline-none pl-3 py-2 bg-white text-base  text-indigo-950 "
            name="message"
            rows={5}
            placeholder="You can type your message here."
            maxLength={500}
            required
          ></textarea>

          <input
            className="w-28 mx-auto my-3 md:my-6 rounded-3xl outline-none pb-1 text-lg bg-indigo-950 hover:bg-indigo-900 text-white cursor-pointer"
            type="submit"
            value="Submit"
          />
        </form>
      </div>
    </>
  );
};
