import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

export const WordOrder = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = "Order the words to make sentences.";
  const questions = [
    {
      id: 1,
      question: "in the evening / they / at the hotel / arrived.",
      answer: "They arrived at the hotel in the evening.",
    },
    {
      id: 2,
      question: "calls / every night / she / her mother.",
      answer: "She calls her mother every night.",
    },
    {
      id: 3,
      question: "don't study / they / at school / English.",
      answer: "They don't study English at school.",
    },
    {
      id: 4,
      question: "every day / three glasses of tea / drinks / he.",
      answer: "He drinks three glasses of tea every day.",
    },
    {
      id: 5,
      question: "My sister / cats / doesn't like.",
      answer: "My sister doesn't like cats.",
    },
    {
      id: 6,
      question: "in a cafe / Tom / on Saturday night / his friends / met.",
      answer: "Tom met his friends in a cafe on Saturday night.",
    },
  ];

  useTitle(!farsi ? title : "ترتیب کلمات");

  return (
    <>
      <div data-aos="fade" data-aos-duration="1500">
        {/* ---------- Breadcrumb Menu ---------- */}
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Word Order</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* ---------- Language Switch ---------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* ---------- Body in English ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Word Order in English
          </h1>
          <p className="mb-5">
            <mark>Most</mark> sentences in English follow the same word order:
          </p>
          <p className="max-sm:text-base mb-9 text-center">
            <span className="font-bold">S</span>ubject
            <sup className="text-pink-700">1</sup> +{" "}
            <span className="font-bold">V</span>erb
            <sup className="text-pink-700">2</sup> +{" "}
            <span className="font-bold">O</span>bject
            <sup className="text-pink-700">3</sup> +{" "}
            <span className="font-bold">P</span>lace
            <sup className="text-pink-700">4</sup> +{" "}
            <span className="font-bold">T</span>ime
            <sup className="text-pink-700">5</sup>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              John<sup className="text-pink-700">1</sup> met
              <sup className="text-pink-700">2</sup> Nicole
              <sup className="text-pink-700">3</sup> in the park
              <sup className="text-pink-700">4</sup> yesterday
              <sup className="text-pink-700">5</sup>.
            </p>
          </div>
          <p className="mb-9">
            In order to have a sentence, we <mark>usually</mark> need to have a
            subject and a verb. Some sentences might have an object, an adverb
            of place, or/and an adverb of time as well.
          </p>
          <div className="w-5/6 mx-auto px-4 pt-3 rounded-lg bg-pink-50 border shadow-md italic mb-12">
            <p className="font-semibold pb-3">Examples:</p>
            <p className="pb-3">
              She<sup className="text-pink-700">1</sup> is sleeping
              <sup className="text-pink-700">2</sup>.
            </p>
            <p className="mb-3 italic">
              He<sup className="text-pink-700">1</sup> loves
              <sup className="text-pink-700">2</sup> her
              <sup className="text-pink-700">3</sup>.
            </p>
            <p className="pb-3">
              I<sup className="text-pink-700">1</sup> saw
              <sup className="text-pink-700">2</sup> her
              <sup className="text-pink-700">3</sup> last night
              <sup className="text-pink-700">5</sup>.
            </p>
            <p className="mb-3 italic">
              The students<sup className="text-pink-700">1</sup> play
              <sup className="text-pink-700">2</sup> basketball
              <sup className="text-pink-700">3</sup> in the gym
              <sup className="text-pink-700">4</sup> every day
              <sup className="text-pink-700">5</sup>.
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Word Order in English <br />
            <span lang="fa" className="text-2xl">
              ترتیب کلمات در جملات انگلیسی
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            می شود گفت که ترتیب کلمات در <mark>بیشتر</mark> جملات زبان انگلیسی،
            از چپ به راست، اینگونه است:
          </p>
          <p lang="fa" dir="rtl" className="max-sm:text-base mb-9 text-center">
            قید زمان
            <sup className="text-pink-700">5</sup> + قید مکان
            <sup className="text-pink-700">4</sup> + مفعول
            <sup className="text-pink-700">3</sup> + فعل
            <sup className="text-pink-700">2</sup> + فاعل
            <sup className="text-pink-700">1</sup>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="italic">
              John<sup className="text-pink-700">1</sup> met
              <sup className="text-pink-700">2</sup> Nicole
              <sup className="text-pink-700">3</sup> in the park
              <sup className="text-pink-700">4</sup> yesterday
              <sup className="text-pink-700">5</sup>.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="mb-9">
            <mark>معمولا</mark> برای بیان یک جمله درست در زبان انگلیسی حداقل به
            یک فاعل و یک فعل احتیاج داریم. بسته به شرایط ممکن است جمله ما مفعول،
            قید مکان و/یا قید زمان نیز داشته باشد.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 border shadow-md mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>
            <p className="mb-3 italic ">
              She<sup className="text-pink-700">1</sup> is sleeping
              <sup className="text-pink-700">2</sup>.
            </p>
            <p className="mb-3 italic">
              He<sup className="text-pink-700">1</sup> loves
              <sup className="text-pink-700">2</sup> her
              <sup className="text-pink-700">3</sup>.
            </p>
            <p className="mb-3 italic">
              I<sup className="text-pink-700">1</sup> saw
              <sup className="text-pink-700">2</sup> her
              <sup className="text-pink-700">3</sup> last night
              <sup className="text-pink-700">5</sup>.
            </p>
            <p className="mb-3 italic">
              The students<sup className="text-pink-700">1</sup> play
              <sup className="text-pink-700">2</sup> basketball
              <sup className="text-pink-700">3</sup> in the gym
              <sup className="text-pink-700">4</sup> every day
              <sup className="text-pink-700">5</sup>.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
