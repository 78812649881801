import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LinkImg from "../../../assets/images/link.svg";

export const QuantifiersElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Choose the correct expression printed in{" "}
      <span className="italic">italics</span>.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          I don't have <span className="italic">many / much</span> time today.
        </span>
      ),
      answer: (
        <span>
          I don't have <span className="font-bold">much</span> time today.
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          We bought <span className="italic">a few / a little</span> apples at
          the market.
        </span>
      ),
      answer: (
        <span>
          We bought <span className="font-bold">a few</span> apples at the
          market.
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          There aren't <span className="italic">none / any</span> eggs in the
          fridge.
        </span>
      ),
      answer: (
        <span>
          There aren't <span className="font-bold">any</span> eggs in the
          fridge.
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          My teacher gave us <span className="italic">a lot of / much</span>{" "}
          homework yesterday.
        </span>
      ),
      answer: (
        <span>
          My teacher gave us <span className="font-bold">a lot of</span>{" "}
          homework yesterday.
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>
          I have <span className="italic">a few / a little</span> questions to
          ask the teacher.
        </span>
      ),
      answer: (
        <span>
          I have <span className="font-bold">a few</span> questions to ask the
          teacher.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          They don’t have <span className="italic">a few / any</span> sugar
          left, so we can't make a cake.
        </span>
      ),
      answer: (
        <span>
          They don’t have <span className="font-bold">any</span> sugar left, so
          we can't make a cake.
        </span>
      ),
    },
    {
      id: 7,
      question: (
        <span>
          How <span className="italic">many / much</span> milk do you drink
          every day?
        </span>
      ),
      answer: (
        <span>
          How <span className="font-bold">much</span> milk do you drink every
          day?
        </span>
      ),
    },
    {
      id: 8,
      question: (
        <span>
          We saw <span className="italic">lots of / much</span> birds at the
          park this morning.
        </span>
      ),
      answer: (
        <span>
          We saw <span className="font-bold">lots of</span> birds at the park
          this morning.
        </span>
      ),
    },
    {
      id: 9,
      question: (
        <span>
          "How much water is in the bottle?"
          <span className="ml-8">
            "<span className="italic">Any / None</span>."
          </span>
        </span>
      ),
      answer: (
        <span>
          "How much water is in the bottle?"
          <span className="ml-8">
            "<span className="font-bold">None</span>."
          </span>
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Quantifiers</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Quantifiers
          </h1>
          <p className="mb-12">
            Quantifiers are expressions we use to talk about how much or how
            many of something there is. They tell us the number of things or
            people, or the amount of something.
          </p>

          <h2 className="mb-5 text-2xl md:text-3xl font-bold text-pink-700">
            Large Quantities
          </h2>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">a lot of // lots of</span>
          </div>

          <p className="mb-5">
            We can put "a lot of" or "lots of" before{" "}
            <mark>countable plural nouns</mark> or{" "}
            <mark>uncountable nouns</mark> in{" "}
            <span className="underline">positive</span> and{" "}
            <span className="underline">negative</span> sentences, as well as in{" "}
            <span className="underline">questions</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-5">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="mb-2">There were a lot of people on the street.</p>
            <p className="mb-2">Do you drink lots of water?</p>
          </div>

          <p className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In negative sentences and questions, "a lot of" and "lots of"
              indicate small quantities.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="mb-2">She doesn't have lots of friends.</p>
            <p className="mb-2">There isn't a lot of information.</p>
          </div>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">many</span>
          </div>

          <p className="mb-5">
            We put "many" before <mark>countable plural nouns</mark> in{" "}
            <span className="underline">positive</span> and{" "}
            <span className="underline">negative</span> sentences, as well as in{" "}
            <span className="underline">questions</span>.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-5">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="mb-2">There were many books on the shelf.</p>
            <p className="mb-2">Do you have many friends?</p>
          </div>

          <p className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In negative sentences and questions, "many" indicates small
              quantities.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="mb-2">He doesn't have many friends.</p>
          </div>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">much</span>
          </div>

          <p className="mb-5">
            We put "much" before <mark>uncountable nouns</mark> in{" "}
            <span className="underline">negative</span> sentences, and{" "}
            <span className="underline">questions</span>. "Much" is not commonly
            used in positive sentences.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-5">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="mb-2">Is there much milk left in the fridge?</p>
          </div>

          <p className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In negative sentences and questions, "much" indicates small
              quantities.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="mb-2">We don't have much time.</p>
          </div>

          <h2 className="mb-5 text-2xl md:text-3xl font-bold text-pink-700">
            Small Quantities
          </h2>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">a few</span>
          </div>

          <p className="mb-5">
            We put "a few" before <mark>countable plural nouns</mark> in{" "}
            <span className="underline">positive</span> sentences. We don't use
            it in negative sentences or questions.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="mb-2">She bought a few apples at the store.</p>
          </div>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">a little</span>
          </div>

          <p className="mb-5">
            We put "a little" before <mark>uncountable nouns</mark> in{" "}
            <span className="underline">positive</span> sentences. We don't use
            it in negative sentences or questions.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="mb-2">I have a little money.</p>
          </div>

          <h2 className="mb-5 text-2xl md:text-3xl font-bold text-pink-700">
            Zero Quantity
          </h2>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">not ... any</span>
          </div>

          <p className="mb-5">
            We can put "any" before <mark>countable plural nouns</mark> or{" "}
            <mark>uncountable nouns</mark> in{" "}
            <span className="underline">negative</span> sentences for zero
            quantity.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="mb-2">There aren't any cookies left.</p>
            <p className="mb-2">There isn't any water in the bottle.</p>
          </div>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">none</span>
          </div>

          <p className="mb-5">
            In response to "How many ...?" or "How much ...?", we can use "none"
            as a short answer to refer to zero quantity.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <div className="mb-3">
              <p>"How many apples do you have?"</p>
              <p>"None."</p>
            </div>
            <p className="mb-">"How much money do you have?"</p>
            <p className="mb-">"None."</p>
          </div>

          <h2 className="mb-5 text-2xl md:text-3xl font-bold text-pink-700">
            Questions
          </h2>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">How many ...?</span>
          </div>

          <p className="mb-5">
            We can use the question word 'how many' before a{" "}
            <mark>countable plural noun</mark> to ask about the number of people
            or things.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p> How many neighbors do
            you have?
          </div>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">How much ...?</span>
          </div>

          <p className="mb-5">
            We can use the question word 'how much' before an{" "}
            <mark>uncountable noun</mark> to ask about the amount of something.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-16">
            <p className="font-semibold pb-3">Example:</p> How much money do you
            need?
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/uncountableNouns">
                Countable / Uncountable Nouns{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Quantifiers
          </h1>
          <p lang="fa" dir="rtl" className="mb-12">
            در دستور زبان انگلیسی، quantifiers کلماتی هستند که مقدار یا تعداد
            چیز ها را نشان می دهند.
          </p>

          <h2
            lang="fa"
            dir="rtl"
            className="mb-5 text-2xl md:text-3xl font-bold text-pink-700"
          >
            مقادیر زیاد
          </h2>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">a lot of // lots of</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            عبارت های a lot of و lots of قبل از{" "}
            <mark>اسامی قابل شمارش جمع</mark>، و{" "}
            <mark>اسم های غیر قابل شمارش</mark> استفاده می شوند. می توانیم آنها
            را هم در جملات <mark>مثبت</mark> و <mark>منفی</mark>، و هم در جملات{" "}
            <mark>سوالی</mark> استفاده کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-5">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">There were a lot of people on the street.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              مردم زیادی در خیابان بودند.
            </p>
            <p className="mb-1">Do you drink lots of water?</p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              آیا تو زیاد آب می خوری؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              طبیعتا وقتی این ساختار ها را در جملات منفی استفاده می کنیم، بر
              مقادیر کم دلالت دارند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">She doesn't have lots of friends.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              او دوستان زیادی ندارد. (تعداد دوستانش کم است.)
            </p>
            <p className="mb-1">There isn't a lot of information.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              اطلاعات زیادی در دسترس نیست. (اطلاعات کمی وجود دارد.)
            </p>
          </div>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">many</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            کلمه many را قبل از <mark>اسامی قابل شمارش جمع</mark>، در جملات{" "}
            <mark>مثبت</mark> و <mark>منفی</mark> و همچنین در جملات{" "}
            <mark>سوالی</mark> استفاده می کنیم.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-5">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">There were many books on the shelf.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              کتاب های زیادی تو قفسه بود.
            </p>
            <p className="mb-1">Do you have many friends?</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              تو دوستان زیادی داری؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              می توانیم برای نشان دادن مقادیر کم many را در جملات منفی استفاده
              کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">He doesn't have many friends.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              او دوستان زیادی ندارد. (تعداد دوستانش کم است.)
            </p>
          </div>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">much</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            کلمه much را قبل از <mark>اسم های غیر قابل شمارش</mark> در جملات{" "}
            <mark>سوالی</mark> و <mark>منفی</mark> استفاده می کنیم. کلمه much
            معمولا در جملات خبری مثبت استفاده نمی شود.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-5">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">Is there much milk left in the fridge?</p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              آیا مقدار زیادی شیر در یخچال باقی مانده است؟
            </p>
          </div>

          <p dir="rtl" className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pl-3">
              برای نشان دادن مقادیر کم، می توانیم از much در جملات منفی استفاده
              کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">We don't have much time.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              خیلی وقت نداریم. (وقت کمی داریم.)
            </p>
          </div>

          <h2
            lang="fa"
            dir="rtl"
            className="mb-5 text-2xl md:text-3xl font-bold text-pink-700"
          >
            مقادیر کم
          </h2>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">a few</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            عبارت a few را قبل از <mark>اسم های قابل شمارش جمع</mark> در جملات{" "}
            <mark>مثبت</mark> استفاده می کنیم. این عبارت معمولا در جملات منفی و
            سوالی استفاده نمی شود.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">She bought a few apples at the store.</p>
            <p lang="fa" dir="rtl" className="mb-2">
              او چند عدد / کمی سیب از مغازه خرید.
            </p>
          </div>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">a little</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            عبارت a little را معمولا قبل از <mark>اسامی غیر قابل شمارش</mark> در
            جملات <mark>خبری مثبت</mark> استفاده می کنیم. این عبارت معمولا در
            جملات منفی و سوالی استفاده نمی شود.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">I have a little money.</p>
            <p lang="fa" dir="rtl" className="mb-2">
              کمی پول دارم.
            </p>
          </div>

          <h2
            lang="fa"
            dir="rtl"
            className="mb-5 text-2xl md:text-3xl font-bold text-pink-700"
          >
            کمیت صفر
          </h2>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">not ... any</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            برای نشان دادن کمیت صفر یا هیچ می توانیم در جملات <mark>منفی</mark>{" "}
            از کلمه any قبل از <mark>اسم های قابل شمارش جمع</mark> یا{" "}
            <mark>اسم های غیر قابل شمارش</mark>، استفاده کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">There aren't any cookies left.</p>
            <p lang="fa" dir="rtl" className="mb-2">
              هیچ بیسکوییتی باقی نمانده است.
            </p>
            <p className="mb-1 italic">There isn't any water in the bottle.</p>
            <p lang="fa" dir="rtl" className="mb-2">
              در بطری (هیچ) آب نیست.
            </p>
          </div>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">none</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            در جواب به سوالاتی که با How many یا How much شروع می شوند، می
            توانیم از کلمه none به تنهایی به عنوان پاسخ کوتاهی که به کمیت صفر یا
            هیچ دلالت می کند استفاده کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <div className="mb-1 italic">
              <p>"How many apples do you have?"</p>
              <p>"None."</p>
            </div>
            <div className="mb-3">
              <p lang="fa" dir="rtl">
                «چند تا سیب داری؟»
              </p>
              <p lang="fa" dir="rtl">
                «هیچ چی.»
              </p>
            </div>
            <div className="mb-1 italic">
              <p className="mb-">"How much money do you have?"</p>
              <p className="mb-">"None."</p>
            </div>
            <div className="mb-3">
              <p lang="fa" dir="rtl">
                «چقدر پول داری؟»
              </p>
              <p lang="fa" dir="rtl">
                «هیچ چی.»
              </p>
            </div>
          </div>

          <h2
            lang="fa"
            dir="rtl"
            className="mb-5 text-2xl md:text-3xl font-bold text-pink-700"
          >
            سوال ها
          </h2>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">?... How many</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            برای سوال پرسیدن در باره تعداد چیز ها یا افراد عبارت سوالی how many
            را قبل از <mark>اسم های قابل شمارش جمع</mark> استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">How many neighbors do you have?</p>
            <p lang="fa" dir="rtl" className="not-italic">
              چند تا همسایه دارید؟
            </p>
          </div>

          <div dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">?... How much</span>
          </div>

          <p lang="fa" dir="rtl" className="mb-5">
            برای سوال پرسیدن درباره مقدار یک چیز از عبارت سوالی how much قبل از{" "}
            <mark>اسامی غیر قابل شمارش</mark> استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-16">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">How much money do you need?</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              چقدر پول احتیاج داری؟
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/uncountableNouns">
                Countable / Uncountable Nouns{" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
