import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const FutureSimpleElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences using "will" and the verb in parentheses. Use
      contractions whenever possible.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: <span>I ____ you tomorrow. (call)</span>,
      answer: (
        <span className="font-normal">
          I<span className="font-bold">'ll call</span> you tomorrow.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>They ____ to London this year. (not / travel)</span>,
      answer: (
        <span>
          They <span className="font-bold">won't travel</span> to London this
          year.
        </span>
      ),
    },
    {
      id: 3,
      question: <span>When ____ you ____ back? (come)</span>,
      answer: (
        <span>
          When <span className="font-bold">will</span> you{" "}
          <span className="font-bold">come</span> back?
        </span>
      ),
    },
    {
      id: 4,
      question: <span>I hope it ____ tomorrow. (not / rain)</span>,
      answer: (
        <span>
          I hope it <span className="font-bold">won't rain</span> tomorrow.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>I think you ____ reading this book. (enjoy)</span>,
      answer: (
        <span>
          I think you<span className="font-bold">'ll enjoy</span> reading this
          book.
        </span>
      ),
    },
    {
      id: 6,
      question: <span>____ the meeting ____ long? (be)</span>,
      answer: (
        <span>
          <span className="font-bold">Will</span> the meeting{" "}
          <span className="font-bold">be</span> long?
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Future Simple</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Future Simple
          </h1>
          <div className="mb-16">
            <p className="mb-2">
              We use the Future Simple tense (will + base form of a verb):
            </p>
            <div className="flex">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">to make predictions about the future</span>
            </div>

            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p className="font-semibold pb-6 italic">Examples:</p>{" "}
              <p className="pb-1 italic">
                She <span className="font-bold">will become</span> a good artist
                some day.
              </p>
              <p className="pb-1 italic">
                It <span className="font-bold">will be</span> cold tonight.
              </p>
            </div>
            <div className="flex">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                to talk about facts or certainties about the future
              </span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p className="font-semibold pb-6 italic">Examples:</p>{" "}
              <p className="pb-1 italic">
                My father <span className="font-bold">will be</span> 60 years
                old next week.
              </p>
              <p className="pb-1 italic">
                The sun <span className="font-bold">will rise</span> at 6 a.m.
              </p>
            </div>
          </div>

          <p className="mb-9">
            Below is an overview of the different forms of the Future Simple
            tense for the verb "do".
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Positive</h3>
            <p>
              I <span className="font-bold">will</span> / I
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              You <span className="font-bold">will</span> / You
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              He <span className="font-bold">will</span> / He
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              She <span className="font-bold">will</span> / She
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              It <span className="font-bold">will</span> / It
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              We <span className="font-bold">will</span> / We
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              You <span className="font-bold">will</span> / You
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              They <span className="font-bold">will</span> / They
              <span className="font-bold">'ll</span> do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Negative</h3>
            <p>
              I <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... / I
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              You <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              You
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              He <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              He
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              She <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              She
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              It <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              It
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              We <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              We
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              You <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              You
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              They <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              They
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Will</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> he do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> she do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> it do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> we do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you do... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> they do ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Will</span> I{" "}
              <span className="font-semibold text-red-700">not</span> do ...? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> he{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> he do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> she{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> she do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> it{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> it do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> we{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> we do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> they{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> they do
              ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">will</span>. / No, I
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">will</span>. / No, you{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">will</span>. / No, he{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">will</span>. / No, she{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">will</span>. / No, it{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">will</span>. / No, we{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">will</span>. / No, you{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">will</span>. / No, they{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, we use the auxiliary verb "will" to make negative
              sentences and questions in the Future Simple tense.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">It will / It'll happen.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">It will not / won't happen.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Will it happen?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Won't it happen? / Will it not happen?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, it will. / No, it won't.</span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Future Simple
          </h1>
          <div className="mb-16">
            <p lang="fa" dir="rtl" className="mb-2">
              در شرایط زیر از ساختار will + base verb استفاده می کنیم:
            </p>
            <div lang="fa" dir="rtl" className="flex">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">
                وقتی می خواهیم راجع به پیش بینی مان در باره آینده صحبت کنیم.
              </span>
            </div>

            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p lang="fa" dir="rtl" className="font-semibold pb-6">
                مثال:
              </p>{" "}
              <p className="pb-1 italic">
                She <span className="font-bold">will become</span> a good artist
                some day.
              </p>
              <p lang="fa" dir="rtl" className="mb-2">
                یک روز هنرمند خوبی خواهد شد.
              </p>
              <p className="pb-1 italic">
                It <span className="font-bold">will be</span> cold tonight.
              </p>
              <p lang="fa" dir="rtl" className="mb-1">
                امشب هوا سرد خواهد شد.
              </p>
            </div>
            <div dir="rtl" className="flex">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span lang="fa" className="mt-1">
                وقتی می خواهیم راجع به یک حقیقت یا قطعیت در باره آینده صحبت
                کنیم:
              </span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p lang="fa" dir="rtl" className="font-semibold pb-6">
                مثال:
              </p>{" "}
              <p className="pb-1 italic">
                My father <span className="font-bold">will be</span> 60 years
                old next week.
              </p>
              <p lang="fa" dir="rtl" className="mb-2">
                هفته آینده پدرم شصت ساله خواهد شد.
              </p>
              <p className="pb-1 italic">
                The sun <span className="font-bold">will rise</span> at 6 a.m.
              </p>
              <p lang="fa" dir="rtl" className="mb-1">
                خورشید ساعت شش طلوع خواهد کرد.
              </p>
            </div>
          </div>

          <p dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="mr-3">
              همانطور که در مثال های بالا مشاهده می کنید، بعد از فعل کمکی will
              باید شکل ساده یک فعل اصلی را داشته باشیم.
            </span>
          </p>

          <p lang="fa" dir="rtl" className="mb-9">
            در ادامه ساختار های مختلف جمله در این زمان را برای فعل do، به عنوان
            مثال، مشاهده می کنید.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Positive</h3>
            <p>
              I <span className="font-bold">will</span> / I
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              You <span className="font-bold">will</span> / You
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              He <span className="font-bold">will</span> / He
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              She <span className="font-bold">will</span> / She
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              It <span className="font-bold">will</span> / It
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              We <span className="font-bold">will</span> / We
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              You <span className="font-bold">will</span> / You
              <span className="font-bold">'ll</span> do ...
            </p>
            <p>
              They <span className="font-bold">will</span> / They
              <span className="font-bold">'ll</span> do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Negative</h3>
            <p>
              I <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... / I
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              You <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              You
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              He <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              He
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              She <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              She
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              It <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              It
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              We <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              We
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              You <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              You
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
            <p>
              They <span className="font-semibold">will</span>{" "}
              <span className="font-semibold text-red-700">not</span> do ... /
              They
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span> do ...
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Will</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> he do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> she do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> it do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> we do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you do... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> they do ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Will</span> I{" "}
              <span className="font-semibold text-red-700">not</span> do ...? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> he{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> he do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> she{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> she do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> it{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> it do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> we{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> we do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Will</span> they{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Wo</span>
              <span className="font-semibold text-red-700">n't</span> they do
              ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">will</span>. / No, I
              <span className="font-semibold"> wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">will</span>. / No, you{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">will</span>. / No, he{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">will</span>. / No, she{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">will</span>. / No, it{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">will</span>. / No, we{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">will</span>. / No, you{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">will</span>. / No, they{" "}
              <span className="font-semibold">wo</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="mr-3">
              همانطور که مشاهده می کنید، در این نوع جملات برای منفی و سوالی کردن
              جمله از فعل کمکی will استفاده می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">It will / It'll happen.</span>
            </p>
            <p lang="fa" dir="rtl" className="flex mb-2">
              اتفاق خواهد افتاد.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">It will not / won't happen.</span>
            </p>
            <p lang="fa" dir="rtl" className="flex mb-2">
              اتفاق نخواهد افتاد.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Will it happen?</span>
            </p>
            <p lang="fa" dir="rtl" className="flex mb-2">
              آیا این اتفاق خواهد افتاد؟
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Won't it happen? / Will it not happen?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="flex mb-2">
              آیا این اتفاق نخواهد افتاد؟
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, it will. / No, it won't.</span>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
