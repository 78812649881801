import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import ArrowLongRight from "../../../assets/images/arrowLongLight.svg";
import LinkImg from "../../../assets/images/link.svg";

export const AdverbsElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Choose the correct word printed in <span className="italic">italics</span>
      .
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          She plays the guitar{" "}
          <span className="italic">beautiful / beautifully</span>.
        </span>
      ),
      answer: (
        <span>
          She plays the guitar <span className="font-bold">beautifully</span>.
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          My brother is a <span className="italic">careful / carefully</span>{" "}
          driver.
        </span>
      ),
      answer: (
        <span>
          My brother is a <span className="font-bold">careful</span> driver.
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          He always speaks <span className="italic">polite / politely</span> to
          everyone.
        </span>
      ),
      answer: (
        <span>
          He always speaks <span className="font-bold">politely</span> to
          everyone.
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          My friend can run very <span className="italic">fast / fastly</span>.
        </span>
      ),
      answer: (
        <span>
          My friend can run very <span className="font-bold">fast</span>.
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>
          The landscape was{" "}
          <span className="italic">incredible / incredibly</span> beautiful.
        </span>
      ),
      answer: (
        <span>
          The landscape was <span className="font-bold">incredibly</span>{" "}
          beautiful.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          She wears <span className="italic">casual / casually</span> clothes
          everywhere.
        </span>
      ),
      answer: (
        <span>
          She wears <span className="font-bold">casual</span> clothes
          everywhere.
        </span>
      ),
    },
    {
      id: 7,
      question: (
        <span>
          He finished the test <span className="italic">slow / slowly</span> but
          got all the answers right.
        </span>
      ),
      answer: (
        <span>
          He finished the test <span className="font-bold">slow / slowly</span>{" "}
          but got all the answers right.
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "صفات عالی / برترین");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Adverbs</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-10 drop-shadow">
            Adverbs
          </h1>
          <p className="mb-6">
            Adverbs of manner describe how something happens or how someone does
            something. They describe the manner in which a verb happens.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              He drives <span className="font-bold">carefully</span>.
            </p>
            <p className="pb-2">
              She speaks English <span className="font-bold">well</span>.
            </p>
          </div>

          <p className="mb-6">
            In the first example above, the adverb "carefully" shows the way he{" "}
            <span className="italic">drives</span>. In the second example, the
            adverb "well" describes the manner in which she{" "}
            <span className="italic">speaks</span> English. Most adverbs of
            manner often come after the verb (or verb phrase).
          </p>

          <p className="mb-6">
            In most cases, we add "-ly" to an adjective to form an adverb.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>slow + ly</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>slowly</span>
            </p>
            <p className="flex pb-2">
              <span>
                happ<span className="font-bold">y</span> + ly
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                happ<mark className="font-bold">i</mark>ly
              </span>
            </p>
          </div>

          <p className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Some adverbs do not follow this pattern. They are irregular.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>good</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>well</span>
            </p>
            <p className="flex pb-2">
              <span>hard</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>hard</span>
            </p>
            <p className="flex pb-2">
              <span>fast</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>fast</span>
            </p>
          </div>

          <p className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Words like "very", "really", and "incredibly" are modifying
              adverbs. They are used before an adjective or another adverb to
              show the degree or intensity of that adjective or adverb.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="mb-2">They were very friendly.</p>
            <p className="mb-2">He was driving incredibly fast.</p>
          </div>

          <p className="mb-20">
            In the first example above, "very" is describing the degree of the
            adjective "friendly". In the second example, "incredibly" is
            describing the intensity of the adverb "fast"
          </p>
          <div className="mb-10">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/adjectives">
                Adjectives <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Adverbs <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              قید ها
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            برای توصیف چگونگی یا شیوه رخداد فعل از قید های manner استفاده می
            کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-4">
              مثال:
            </p>
            <p className="pb-1 italic">
              He drives <span className="font-bold">carefully</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              او <span className="font-bold">با احتیاط</span> رانندگی می کند.
            </p>
            <p className="pb-1 italic">
              She speaks English <span className="font-bold">well</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              او <span className="font-bold">خوب</span> انگلیسی صحبت می کند.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-6">
            در جمله اول قید carefully طریقه رانندگی کردن شخص را نشان می دهد. در
            جمله دوم قید well چگونگی انگلیسی صحبت کردن شخص را نشان می دهد.
            همانطور که مشاهده می کنید اغلب قید های manner معمولا بعد از فعل یا
            عبارت فعلی جمله می آیند.
          </p>

          <p lang="fa" dir="rtl" className="mb-6">
            در بیشتر موارد وقتی به یک صفت "ly-" اضافه کنیم، تبدیل به قید می شود.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-2">
              <span>slow + ly</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>slowly</span>
            </p>
            <p className="flex pb-2">
              <span>
                happ<span className="font-bold">y</span> + ly
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                happ<mark className="font-bold">i</mark>ly
              </span>
            </p>
          </div>

          <p dir="rtl" className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pr-3">
              تعداد کمی از قید های manner بی قاعده هستند و از قانون بالا پیروی
              نمی کنند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-2">
              <span>good</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>well</span>
            </p>
            <p className="flex pb-2">
              <span>hard</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>hard</span>
            </p>
            <p className="flex pb-2">
              <span>fast</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>fast</span>
            </p>
          </div>

          <p dir="rtl" className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pr-3">
              کلماتی مانند very ،incredibly و really در زبان انگلیسی modifying
              adverbs نامیده می شوند. به عبارت دیگر آنها قید هایی هستند که قبل
              از یک صفت یا یک قید دیگر می نشینند تا شدت و درجه آن صفت یا قید را
              نشان دهند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">They were very friendly.</p>
            <p lang="fa" dir="rtl" className="mb-2">
              رفتارشان خیلی دوستانه بود. // خیلی مهربان بودند.
            </p>
            <p className="mb-1 italic">He was driving incredibly fast.</p>
            <p lang="fa" dir="rtl" className="mb-1">
              با سرعت خیلی بالایی داشت رانندگی می کرد.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-20">
            در جمله اول بالا کلمه very صفت friendly را توصیف می کند. در جمله دوم
            کلمه incredibly قید fast را توصیف می کند.
          </p>
          <div className="mb-10">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/adjectives">
                Adjectives <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
