import { Link } from "react-router-dom";

export const TitleCard = ({ card }) => {
  const { heading, subheading, url } = card;

  return (
    <Link to={`${url}`}>
      <div className="flex m-2 px-1 md:m-3 transition-all duration-300 w-40 h-24 min-[720px]:w-44 min-[720px]:h-24 min-[784px]:w-48 min-[784px]:h-28 rounded-2xl bg-pink-50 shadow-md  items-center justify-center hover:scale-110 cursor-pointer">
        <p className="flex flex-col text-center">
          <span className="text-pink-700 text-xl min-[720px]:text-xl font-bold drop-shadow mb-1">
            {heading}
          </span>
          <span className="text-indigo-950 font-semibold min-[720px]:text-lg">
            {subheading}
          </span>
        </p>
      </div>
    </Link>
  );
};
