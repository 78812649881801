import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/logo.png";
export const Header = () => {
  const [hidden, setHidden] = useState(true);
  const activeClass =
    "block py-2 px-3 text-white bg-pink-700 rounded md:bg-transparent md:text-pink-600 md:p-0";
  const inActiveClass =
    "transition-all duration-300 block py-2 px-3 text-white rounded hover:bg-pink-700 md:hover:bg-transparent md:border-0 md:hover:text-pink-600 md:p-0";

  return (
    <header>
      <nav className="bg-fuchsia-950">
        <div className="flex max-w-screen-xl flex-wrap items-center justify-between mx-2 lg:mx-12">
          <Link
            onClick={() => setHidden(true)}
            to="/"
            className="flex items-center"
          >
            <img src={Logo} className="h-20" alt="English Hub Online Logo" />
            <div className="self-center text-lg sm:text-xl font-semibold whitespace-nowrap text-white">
              English Hub Online
            </div>
          </Link>
          <button
            onClick={() => setHidden(!hidden)}
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`transition-all duration-500 w-full ${
              hidden
                ? "overflow-hidden max-h-0 opacity-0 md:opacity-100"
                : "max-h-screen"
            } 0 md:block md:overflow-visible md:max-h-screen md:w-auto`}
            id="navbar-default"
          >
            <ul className="flex flex-col rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse mb-4 md:my-0 mr-4">
              <li>
                <NavLink
                  onClick={() => setHidden(!hidden)}
                  to="/grammar"
                  className={({ isActive }) =>
                    isActive ? activeClass : inActiveClass
                  }
                  aria-current="page"
                >
                  Grammar
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setHidden(!hidden)}
                  to="/ielts"
                  className={({ isActive }) =>
                    isActive ? activeClass : inActiveClass
                  }
                >
                  IELTS
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setHidden(!hidden)}
                  to="/commonMistakes"
                  className={({ isActive }) =>
                    isActive ? activeClass : inActiveClass
                  }
                >
                  Common Mistakes
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setHidden(!hidden)}
                  to="/underConstruction"
                  className={({ isActive }) =>
                    isActive ? activeClass : inActiveClass
                  }
                >
                  Articles
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
